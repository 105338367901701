<style>
	@charset "utf-8";
	@import "@/assets/css/common/app.css";
	@import "@/assets/css/common/module.css";
</style>
<template>
	<router-view></router-view>
</template>

<script>
	export default {
		components: {},
		computed: {},
	};
</script>
