import { createRouter, createWebHistory } from "vue-router";
const routes = [
	{
		path: "/",
		redirect: "/",
		component: () => import("@/layouts/app.vue"),
		children: [
			{
				path: "/",
				name: "top",
				component: () => import("@/pages/top/top.vue"),
				meta: {
					title: "Home - WIT LAB",
					slug: "home",
				},
			},
			{
				path: "profile",
				name: "profile",
				component: () => import("@/pages/profile/profile.vue"),
				meta: {
					title: "Profile - WIT LAB",
					slug: "profile",
				},
			},
			{
				path: "team",
				name: "team",
				component: () => import("@/pages/team/team.vue"),
				meta: {
					title: "Team - WIT LAB",
					slug: "team",
				},
			},
			{
				path: "/team/details/:id",
				name: "team-details",
				component: () => import("@/pages/team/team-details.vue"),
				meta: {
					title: "Team Detail - WIT LAB",
					slug: "team",
				},
			},
			{
				path: "services",
				name: "services",
				component: () => import("@/pages/services/services.vue"),
				meta: {
					title: "Services - WIT LAB",
					slug: "services",
				},
			},
			{
				path: "projects",
				name: "projects",
				component: () => import("@/pages/projects/projects.vue"),
				meta: {
					title: "Project - WIT LAB",
					slug: "projects",
				},
			},
			{
				path: "/project/details/:id",
				name: "project-details",
				component: () => import("@/pages/projects/project-detail.vue"),
				meta: {
					title: "Project Detail - WIT LAB",
					slug: "projects",
				},
			},

			{
				path: "/news",
				name: "news",
				component: () => import("@/pages/news/news.vue"),
				meta: {
					title: "News - WIT LAB",
					slug: "news",
				},
			},
			{
				path: "/jobpost",
				name: "jobpost",
				component: () => import("@/pages/jobpost/jobpost.vue"),
				meta: {
					title: "Job Post - WIT LAB",
					slug: "jobpost",
				},
			},
			{
				path: "/jobpost/details/:id",
				name: "jobpost-details",
				component: () => import("@/pages/jobpost/job-post-details.vue"),
				meta: {
					title: "Job Post Detail - WIT LAB",
					slug: "jobpost",
				},
			},
			{
				path: "/jobpost/:id/applicant-form",
				name: "applicant-form",
				component: () => import("@/pages/jobpost/applicant-form.vue"),
				meta: {
					title: "Job Form - WIT LAB",
					slug: "jobpost",
				},
			},
			{
				path: "/contact",
				name: "contact",
				component: () => import("@/pages/contact/contact.vue"),
				meta: {
					title: "Contact - WIT LAB",
					slug: "contact",
				},
			},
			{
				path: "blogs",
				name: "blog",
				component: () => import("@/pages/blog/blog.vue"),
				meta: {
					title: "Blogs - WIT LAB",
					slug: "blog",
				},
			},
			{
				path: "/blogs/category/:id",
				name: "blog-category",
				component: () => import("@/pages/blog/blog-with-category.vue"),
				meta: {
					title: "Blogs Category - WIT LAB",
					slug: "blog",
				},
			},
			{
				path: "/:pathMatch(.*)*",
				name: "notfound",
				component: () => import("@/components/common/404.vue"),
				meta: {
					title: "404 Error - WIT LAB",
				},
			},
			{
				path: "/404",
				name: "404",
				component: () => import("@/components/common/404.vue"),
				meta: {
					title: "404 Error - WIT LAB",
				},
			},
			{
				path: "500",
				name: "500",
				component: () => import("@/components/common/500.vue"),
				meta: {
					title: "500 Error - WIT LAB",
				},
			},
			{
				path: "error",
				name: "error",
				component: () => import("@/components/common/error.vue"),
				meta: {
					title: "Unknow Error - WIT LAB",
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || "WIT LAB";
	window.scrollTo(0, 0);
	next();
});

export default router;
