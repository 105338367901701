import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(
	VueGtag,
	{
		appName: "WIT LAB",
		defaultGroupName: "PageView",
		pageTrackerEnabled: true,
		pageTrackerScreenviewEnabled: true,
		pageTrackerUseFullPath: true,
		pageTrackerPrependBase: true,
		pageTrackerSkipSamePath: true,
		config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
	},
	router,
);

app.mount("#app");
